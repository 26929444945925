<template lang="pug">
div.text-input__container
  .text-input__error-message(v-if="!!errorMessage && isFocused") {{ errorMessage }}
  label.text-input(
    :for="id"
    v-click-outside="onBlur"
    :class="textInputClass"
  )
    .text-input__icon(v-if="$slots.icon")
      slot(name="icon")
    .text-input__field
      input.text-input__input(
        v-if="masked"
        ref="inputRef"
        :id="id"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown="$emit('keydown', $event)"
        @focus="onFocus"
        @focusout="onFocusout"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :min="min"
        :max="max"
        :autocomplete="autocomplete"
        :[typeAttr]="type"
        :inputmode="inputMode"
        v-maska="masked"
      )
      input.text-input__input(
        v-else
        ref="inputRef"
        :id="id"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown="$emit('keydown', $event)"
        @focus="onFocus"
        @focusout="onFocusout"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :inputmode="inputMode"
        :[typeAttr]="type"
      )
      .text-input__actions(v-if="$slots.default")
        slot
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { vOnClickOutside } from '@vueuse/components';
  import { vMaska } from 'maska/vue';

  export default defineNuxtComponent({
    name: 'TextInputCustom',

    directives: {
      clickOutside: vOnClickOutside,
      maska: vMaska,
    },

    emits: ['input', 'keydown', 'on-focus', 'on-blur', 'on-focusout', 'update:modelValue'],

    expose: ['focusField'],

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },
      modelValue: {
        required: true,
        validator: (value: string | null) => {
          return typeof value === 'string' || value === null;
        },
      },
      placeholder: {
        type: String as PropType<string>,
        default: '',
      },
      maxlength: {
        type: Number as PropType<number>,
        default: -1,
      },
      autocomplete: {
        type: String as PropType<string>,
        default: 'off',
      },
      state: {
        type: String as PropType<string>,
        default: '',
      },
      errorMessage: {
        type: String as PropType<string>,
        default: '',
      },
      type: {
        type: String as PropType<string>,
        default: '',
      },
      inputMode: {
        type: String as PropType<string>,
        default: '',
      },
      masked: {
        type: String as PropType<string>,
        default: '',
      },
      min: {
        type: String as PropType<string>,
        default: undefined,
      },
      max: {
        type: String as PropType<string>,
        default: undefined,
      },
    },

    data() {
      return {
        isFocused: false,
      };
    },

    computed: {
      textInputClass(): Record<string, string> {
        return {
          [`text-input--${this.state}`]: this.state,
        };
      },
      typeAttr() {
        return this.type ? 'type' : null;
      },
    },

    methods: {
      focusField(): void {
        this.$refs?.inputRef?.focus();
      },

      onFocus(): void {
        this.isFocused = true;
        this.$emit('on-focus');
      },

      onBlur(): void {
        this.isFocused = false;
        this.$emit('on-blur');
      },

      onFocusout() {
        this.isFocused = false;
        this.$emit('on-focusout');
      },
    },
  });
</script>

<style lang="scss" scoped>
  .text-input {
    $textInput: &;

    cursor: pointer;
    background-color: #fff;
    display: flex;

    @include hover {
      #{$textInput} {
        &__field {
          border-color: #2962f9;
        }
      }
    }

    &__container {
      position: relative;
    }

    &--error {
      #{$textInput} {
        &__input {
          color: #fd4b4b;
        }

        &__field {
          border-color: #fd4b4b;

          &:hover,
          &:focus-within {
            border-color: #fd4b4b;
          }
        }
      }
    }

    &__input {
      width: 100%;
      padding: 12px 16px;
      @include fontTextBtn;
      font-weight: 400;
      resize: none;
      border: none;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
    }

    &__field {
      display: flex;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      width: 100%;

      &:focus-within {
        border-color: #2962f9;
      }
    }

    &__error-message {
      @include normalText;
      position: absolute;
      padding: 16px;
      top: 65px;
      background-color: white;
      border-radius: 4px;
      z-index: 1;
      box-shadow: 0px 0px 8px 0px #00000029;

      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
      }
    }

    &__actions {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
